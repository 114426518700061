<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          จัดการสาขา
        </span>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              @click="goToCreate()"
              color="#2AB3A3"
              dark
              style="height: 48px"
              >+ เพิ่มสาขาใหม่</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="3">
              <v-text-field
                hide-details
                class="mb-4"
                v-model="search"
                dense
                outlined
                placeholder="ค้นหา"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                hide-details
                v-model="filterMajor"
                :items="['ทั้งหมด', 'ปริญญาเอก', 'ปริญญาโท']"
                dense
                outlined
                @change="filterMajors()"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersBrand"
            :items="items"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
              <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row class="mt-10">
      <v-col cols="12">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">จัดการรายวิชา</span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-text-field
                hide-details
              class="mb-4"
              v-model="searchMajorSub"
              dense
outlined
              
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreateMajorSub()" color="#2AB3A3" dark
              >เพิ่มรายวิชาใหม่</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersMajorSub"
            :items="itemsMajorSub"
            :search="searchMajorSub"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="UpdateMajorSub(item)">mdi-pencil</v-icon>
              <v-icon @click="ViewMajorSub(item)">mdi-content-copy</v-icon>
              <v-icon @click="DeleteItemMajorSub(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row> -->

    <!-- <v-row class="mt-10">
      <v-col cols="12">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">
          จัดการผลการเรียนรู้ที่คาดหวัง
        </span>
      </v-col>
      <v-col cols="12" class="ml-5 text-right" md="1" sd="1">
        เลือก สาขาวิชา
      </v-col>
      <v-col cols="12">
        <v-autocomplete
hide-details
          v-model="majorId"
          :items="items"
          dense
outlined
          outlined
          item-value="id"
          @change="getAllHopeful(majorId)"
        >
          <template v-slot:selection="{ item }">
            {{ item.name_th }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.name_th }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="text-right"> เพิ่มผลการเรียนรู้ที่คาดหวัง </v-col>
      <v-col cols="12">
        <v-textarea v-model="description" auto-grow dense
outlined outlined></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn @click="submitHopeful()" color="#2AB3A3" :disabled="!description"
          >เพิ่ม ผลการเรียนรู้ที่คาดหวัง</v-btn
        >
      </v-col>
      <v-col cols="12"></v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersHopeful"
            :items="itemsHopeful"
            :search="searchHopeful"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="DeleteItemHopeful(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row> -->
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      // "id": 6,
      //       "name_en": "name_en",
      //       "name_th": "name_th",
      //       "createdAt": "2022-08-11T03:59:01.000Z",
      //       "updatedAt": "2022-08-11T03:59:01.000Z",
      //       "levelId": null,
      //       "level": null
      headersBrand: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อ (ไทย)", value: "name_th", align: "start" },
        {
          text: "ระดับการศึกษา(ไทย)",
          value: "level.name_th",
          align: "start",
        },
        { text: "คณะ (ไทย)", value: "facualty.name_th", align: "start" },
        {
          text: "รับเฉพาะภาคเรียนที่",
          value: "conditions_for_receiving",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersMajorSub: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อรายวิชา", value: "name", align: "start" },
        { text: "รายละเอียด", value: "description", align: "start" },
        { text: "สาขาวิชาเอก (ไทย)", value: "major.name_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersHopeful: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "สาขา (ไทย)", value: "major.name_th", align: "start" },
        {
          text: "ผลการเรียนรู้ที่คาดหวัง",
          value: "description",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      itemsMajorSub: [],
      itemsHopeful: [],
      search: "",
      searchMajorSub: "",
      searchHopeful: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
      majorId: null,
      description: null,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      filterMajor: "ทั้งหมด",
      itemsMajorFilter: [],
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    this.getAllMajorSub();
  },
  methods: {
    filterMajors() {
      let itemFilter13 = this.itemsMajorFilter.filter((x) => x.levelId == 13);
      let itemFilter12 = this.itemsMajorFilter.filter((x) => x.levelId == 12);
      if (this.filterMajor == "ปริญญาเอก") {
        this.items = itemFilter13;
      } else if (this.filterMajor == "ปริญญาโท") {
        this.items = itemFilter12;
      } else {
        this.items = this.itemsMajorFilter;
      }
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/majors?courseId=${""}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllmajors", response.data.data);
      this.items = response.data.data;
      this.itemsMajorFilter = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAllMajorSub() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/coursesOffereds`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllMajorSub", response.data.data);
      this.itemsMajorSub = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsMajorSub) {
        this.itemsMajorSub[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAllHopeful(majorId) {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/expectedLearningOutcomes?majorId=${majorId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllHopeful", response.data.data);
      this.itemsHopeful = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsHopeful) {
        this.itemsHopeful[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async submitHopeful() {
      if (!this.majorId) {
        this.$swal.fire({
          icon: "error",
          text: `กรุณาเลือกสาขา`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          majorId: this.majorId,
          description: this.description,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/expectedLearningOutcomes`,
          data,
          auth
        );
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `เพิ่มผลการเรียนที่รู้คาดหวังสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.description = null;
          this.getAllHopeful(this.majorId);
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/majors/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
    async DeleteItemHopeful(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/expectedLearningOutcomes/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllHopeful(this.majorId);
        }
      });
    },
    async DeleteItemMajorSub(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/coursesOffereds/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllMajorSub();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateMajor");
    },
    UpdateNews(val) {
      localStorage.setItem("MajorData", Encode.encode(val));
      this.$router.push("EditMajor");
    },
    ViewNews(val) {
      localStorage.setItem("MajorData", Encode.encode(val));
      this.$router.push("ViewMajor");
    },
    goToCreateMajorSub() {
      this.$router.push("CreateMajorSub");
    },
    UpdateMajorSub(val) {
      localStorage.setItem("MajorSubData", Encode.encode(val));
      this.$router.push("EditMajorSub");
    },
    ViewMajorSub(val) {
      localStorage.setItem("MajorSubData", Encode.encode(val));
      this.$router.push("ViewMajorSub");
    },
  },
};
</script>