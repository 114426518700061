<template>
  <div>
    <ManageMajor />
  </div>
</template>
<script>
import ManageMajor from "@/components/ManageMajor/ManageMajor";
export default {
  components: {
    ManageMajor,
  },
  created() {},
};
</script>
